<template>
  <main>
    <div class="sub-text sub-info-text" style="padding: 20px;">
      <img class="attention-img" src="@/assets/attention.png">
Nämä tekstitykset ovat automaattisesti generoituja, joten ei kannata pitää liian suuria odotuksia. Ne kuitenkin auttavat pysymään juonessa mukana. Tekstitykset ovat englanniksi.
    </div>
    <table class="sub-text">
      <tbody v-for="(sub, index) in subtitles" :key="index">
        <tr>
          <td colspan="2">
            <h3>{{ sub.code.toUpperCase() }} — {{ sub.header }}</h3>
          </td>
        </tr>
        <tr>
          <td rowspan="2">
            <img @click="showImg($event)" class="sub-img" :src="require('../assets/subs/' + sub.img)">
          </td>
          <td class="sub-links">
            <a :href="sub.download">[download video]</a><br>
            <a :href="require('../assets/subs/' + sub.sub)">[download srt]</a>
          </td>
        </tr>
        <tr>
          <td class="sub-description">
            {{ sub.description }}
          </td>
        </tr>
      </tbody>
    </table>
    <div id="overlay" class="overlay" @click="toggleImg">
      <img id="overlay-img">
    </div>
  </main>
</template>

<script>

var data = require('../assets/subs.json')

export default {
  name: 'Subs',
  data() {
    return {
      subtitles: data,
      showImage: null
    }
  },
  methods: {
    toggleImg() {
      var el = document.getElementById('overlay')
      el.classList.toggle('overlay-visible')
    },
    showImg(e) {
      document.getElementById('overlay-img').src = e.target.src
      this.toggleImg()
    }
  },
}
</script>

<style scoped>
.overlay {
  display: none;
  width: 100%;
  height: 100%;
}

.overlay-visible {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: black;
}

h1 {
  color: #5e0080;
  font-size: 2.5em;
  font-weight: bold;
}

main {
  margin: auto auto;
  width: 800px;
  padding-bottom: 50px;
}

table {
  margin: auto auto;
}

td {
  vertical-align: top;
}

a {
  font-weight: bold;
}

.sub-img {
  position: relative;
  max-width: 300px;
}

.sub-description {
  padding-left: 20px;
}

.sub-links {
  text-align: right;
}

.sub-text {
  color: #e6f1ff;
  font-family: 'Quicksand', sans-serif;
  font-weight: 200;
  font-size: 1.2em;
}

.sub-info-text {
  border: 1px solid #5e0080;
  margin: 25px;
}

.rating {
  margin-top: 50px;
  margin-bottom: 50px;
  color: #5e0080;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: 1.8em;
}

.attention-img {
  width: 100px;
  float: left;
  margin-left: -15px;
  margin-right: 5px;
}

@media screen and (max-width: 992px) {
  .story-text {
    font-size: 2.5em;
  }
}
</style>
