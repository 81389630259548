<template>
  <div id="video-container">
    <h1 class="header">{{video.header}}</h1>
    <article v-html="video.embed">
      {{video.embed}}
    </article>
    <section class="rating">
      <header>Arvostele video:</header>
      <heart-rating
        @rating-selected="rating_selected($event)"
        :maxRating="10"
        :rating="rating"
        active-color="#550E00">
      </heart-rating>
    </section>
  </div>
</template>

<script>
import {HeartRating} from 'vue-rate-it';

export default {
  name: 'Video',
  components: {
    HeartRating
  },
  data() {
    return {
      video: [],
      rating: 10
    }
  },
  methods: {
    set_rating(value)
    {
      this.rating = value
    },
    rating_selected(value)
    {
      const params = new URLSearchParams({
        rating_key: 'video/' + this.video.video_id,
        rating: value
      });
      var api_uri = '/rate?' + params.toString()
      console.log(api_uri)
      this.api.get(api_uri, (data) => {
        if (data == 'OK') console.log('Arvosteltu: ' + value)
      })
    }
  },
  beforeCreate() {
    var video_id = this.$route.params.id
    this.api.get('/get_video_list?video_id=' + video_id, (data) => {
      this.video = JSON.parse(data)[0]

      var rating_key = 'video/' + this.video.video_id
      var api_uri = '/get_rating?rating_key=' + rating_key
      this.api.get(api_uri, (data) => {
        var video_rating = JSON.parse(data)[0].rating
        this.set_rating((video_rating) ? parseFloat(video_rating) : 0)
      })
    })
  }
}
</script>

<style>
h1 {
  margin: 5px;
  color: #e6f1ff;
}
.header {
  text-align: center;
}
.video {
  margin: auto auto;
  width: 800px;
  height: 600px;
  overflow: hidden;
}
.rating {
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: 1.8em;
  color: #5e0080;
}
</style>
