<template>
  <main>
    <nav class="story-container" v-for='(story, index) in stories' :key="index">
      <router-link :to="{ path: '/story/' + index }">
        <img
          :src="'/assets/' + index + '/' + story.thumbnail"
          :alt="story.header"
          class="story-img">
      </router-link>
    </nav>
  </main>
</template>

<script>
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import data from '../assets/stories.json'

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      stories: data
    }
  },
  methods: {
  },
  created () {
  }
}
</script>

<style>
.story-container {
  text-align: center;
}
.story-img {
  width: 800px;
}
</style>
