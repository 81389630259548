<template>
  <div id="root">
    <div id="main-container">
      <div id="logo-container">
        <router-link to="/">
          <h1 class="logo">Karvakolmio▼</h1>
        </router-link>
      </div>
      <nav class="menu">
        <router-link to="/">Tarinat</router-link>
        <router-link to="/videos">Videot</router-link>
        <router-link to="/subtitles">JAV-subs</router-link>
        <router-link to="/crypto">Tue</router-link>
        <hr class="menu-line">
      </nav>
      <router-view />
    </div>
    <span id="up" class="up" @click="scroll_to_top()">▲</span>
  </div>
</template>

<script>

var hidden = true
var up_button_y = 1500

export default {
  name: 'App',
  components: {
  },
  methods: {
    scroll_to_top()
    {
      window.scrollTo(0,0)
    }
  },
  created() {
    /*
    this.api.get('/get_videos', (data) => {
      console.log('token-check: ', data)
    })
    */
    document.addEventListener('scroll', function() {
      var el = document.querySelector('#up')
      if (hidden && window.scrollY > up_button_y)
      {
        el.style.display = 'block'
        hidden = false
      }
      if (!hidden && window.scrollY < up_button_y)
      {
        el.style.display = 'none'
        hidden = true
      }
    })
  }
}
</script>

<style>
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 100;
  src: url('./assets/fonts/Quicksand-Light.ttf')
}

@font-face {
  font-family: 'Netron';
  font-style: normal;
  src: url('./assets/fonts/Netron-p7gq1.otf')
}

html {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

h1 {
  margin-bottom: 0px;
}

#root {
  padding-bottom: 100px;
}

#main-container {
  margin: auto auto;
  width: 800px;
}

.logo {
  font-family: 'Netron';
  font-weight: 100;
  font-size: 4rem;
  color: #990099;
}

.menu-line {
  border-color: #5e0080;
  margin-top: 0px;
}

.menu a {
  color: #990099;
  margin-right: 10px;
  font-family: Arial;
  font-weight: 200;
  font-size: 1.2rem;
}

.up {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 2px solid #5e0080;
  padding: 15px;
  color: #5e0080;
  font-size: 2.5rem;
  background: #000;
  cursor: pointer;
}
@media screen and (max-width: 992px) {
  .logo {
    font-size: 5rem;
  }
  .menu a {
    font-size: 2.5rem;
  }
  .up {
    font-size: 5rem;
  }
}
</style>
