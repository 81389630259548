<template>
  <main>
    <table id="asset-table">
      <tr v-for="(asset, index) in assets" :key="index">
        <td class="icon-td">
          <img class="icon" :src="imgUrl(asset.icon)" :alt="asset.name"><br>
          {{ asset.name }} ({{ asset.symbol }})
        </td>
        <td class="address-td">{{asset.address}}</td>
      </tr>
    </table>
  </main>
</template>

<script>
import data from '../assets/crypto.json'

export default {
  name: 'Video',
  data() {
    return {
      assets: data
    }
  },
  methods: {
    imgUrl(image) {
      return require('../assets/crypto/' + image)
    }
  },
  created() {
  }
}
</script>

<style>
td {
  color: #e6f1ff;
}
#asset-table {
  margin: auto auto;
  margin-top: 30px;
  width: 600px;
  font-family: 'Quicksand';
}
#asset-table td {
  padding: 10px;
  font-size: 1.2em;
  font-weight: bold;
}
.address-td {
  word-wrap: break-word;
  word-break: break-all;
}
.icon-td {
  text-align: center;
}
.icon {
  width: 75px;
}
@media screen and (max-width: 992px) {
  #asset-table {
    width: 800px;
    font-size: 2em;
  }
}
</style>
