import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $ from 'jquery'

var api = require('./api')($)

api.setToken(
  $('#app').data('token')
)

Vue.prototype.api = api
Vue.prototype._$ = $
Vue.config.productionTip = false

var bus = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

api.setBus(bus)
