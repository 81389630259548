
module.exports = function()
{
  var nav = {}

  nav.arr = []
  nav.fil_arr = []
  nav.page = 1
  nav.page_size = 15
  nav.page_count = 0
  nav.filter_str = ''

  nav.next_page = function()
  {
    nav.page++
    nav.paginate()
    window.scrollTo(0,0)
  }

  nav.last_page = function()
  {
    nav.page--
    nav.paginate()
    window.scrollTo(0,0);
  }

  nav.input = function()
  {
    nav.paginate()
  }

  nav.paginate = function()
  {
    nav.filter()

    if (nav.page > nav.page_count)
    {
      nav.page = nav.page_count
    }

    if (nav.page < 1)
    {
      nav.page = 1
    }

    var start = (nav.page - 1) * nav.page_size
    var end   = nav.page * nav.page_size

    nav.fil_arr = nav.fil_arr.slice(start, end)
  }

  nav.filter = function()
  {
    if (nav.filter_str == '')
    {
      nav.fil_arr = nav.arr
      nav.count_pages()
      return;
    }

    nav.fil_arr = []
    for(var i = 0; i < nav.arr.length; i++)
    {
      var str1 = nav.arr[i].header.toLowerCase()
      var str2 = nav.arr[i].tags.toLowerCase()

      if (str1.indexOf(nav.filter_str.toLowerCase()) > -1 ||
          str2.indexOf(nav.filter_str.toLowerCase()) > -1) {
        nav.fil_arr.push(nav.arr[i])
      }
    }

    nav.count_pages()
  }

  nav.count_pages = function()
  {
    var count = Math.ceil(nav.fil_arr.length / nav.page_size)
    nav.page_count = (count == 0) ? 1 : count
  }

  return nav
}

