<template>
  <main>
    <div class="top-row">
      <input
          ref="filter"
          type="search"
          v-model="nav.filter_str"
          @input="nav.input()"
          placeholder="suodata videoita"
          class="filter"
          autofocus>
      <nav>
        <span @click="nav.last_page()">◄</span>
        {{ nav.page }}/{{ nav.page_count }}
        <span @click="nav.next_page()">►</span>
      </nav>
    </div>
    <div id="video-container" class="videos">
      <div v-for="(video, index) in nav.fil_arr" :key="index">
        <router-link :to="{ path: '/video/' + video.video_id }">
          <article class="video-item">
            <picture>
              <img :src="video.thumbnail" :alt="video.header">
            </picture>
            <div class="video-info">
              <time class="duration">{{video.duration}}</time>
              <span class="rating">{{video.rating}}/10</span>
            </div>
            <header class="header">{{video.header}}</header>
          </article>
        </router-link>
      </div>
    </div>
    <nav>
      <span @click="nav.last_page()">◄</span>
      {{ nav.page }}/{{ nav.page_count }}
      <span @click="nav.next_page()">►</span>
    </nav>
  </main>
</template>

<script>
var nav = require('../mixin/arrnav.js')()

export default {
  name: 'Videos',
  components: {
  },
  data() {
    return {
      filter_str: sessionStorage.getItem('videofilter'),
      nav: nav
    }
  },
  methods: {
    resize() {
      if (window.innerWidth < 992)
      {
        nav.page_size = 10
      } else {
        nav.page_size = 15
      }
      nav.paginate()
    }
  },
  mounted() {
    this.resize()
  },
  beforeCreate() {
    this.api.get('/get_video_list', (data) => {
      nav.arr = JSON.parse(data)
      //nav.page = this.$route.params.page
      nav.paginate()
    })
  },
  created() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
}
</script>

<style scoped>
p {
  margin: 0px;
}
.videos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.video-item {
  border-top: 1px solid #5e0080;
  border-collapse: collapse;
}
table.video-item {
  margin-bottom: 10px;
}
.video-item, .video-item img {
  padding-top: 10px;
  margin: auto auto;
  width: 250px;
  color: #fff;
}
.filter {
  margin: auto auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 600px;
  background-color: #000;
  color: #fff;
  border: 1px solid #990099;
  outline: none !important;
  font-size: 1.3em;
}
.video-info, .top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rating {
  margin-right: 10px;
}
.header {
  font-size: 1.2em;
  text-align: center;
  vertical-align: middle;
  color: #e6f1ff;
}
nav {
  color: #e6f1ff;
  text-align: right;
  font-size: 2em;
  user-select: none;
}
nav span {
  cursor: pointer;
}
.search-nav {
  width: 100%;
}
@media screen and (max-width: 992px) {
  .videos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .video-item, .video-item img {
    width: 380px;
  }
  .rating {
    font-size: 1.5em;
  }
  .duration {
    font-size: 2em;
  }
  .header {
    font-size: 2em;
  }
  .filter {
    font-size: 3em;
    width: 500px;
  }
  nav {
    font-size: 4em;
  }
}
</style>
