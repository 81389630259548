<template>
  <main>
    <!-- <h1>{{ story.header }}</h1> -->
    <img :src="'/assets/' + story.story_id + '/' + story.thumbnail">
    <article class="story-text" v-html="story.body_text">
      {{ story.body_text }}
    </article>
    <section class="rating">
      <header>Arvostele teksti:</header>
      <heart-rating
        @rating-selected="rating_selected($event)"
        :maxRating="10"
        :rating="rating"
        active-color="#550E00">
      </heart-rating>
    </section>
  </main>
</template>

<script>

import {HeartRating} from 'vue-rate-it';
import data from '../assets/stories.json'

export default {
  name: 'App',
  components: {
    HeartRating
  },
  data() {
    return {
      story: data[this.$route.params.id],
      rating: 10
    }
  },
  methods: {
    set_rating(value)
    {
      this.rating = value
    },
    rating_selected(value)
    {
      const params = new URLSearchParams({
        rating_key: 'story/' + this.story.story_id,
        rating: value
      });
      var api_uri = '/rate?' + params.toString()
      this.api.get(api_uri, (data) => {
        if (data == 'OK') console.log('Arvosteltu: ' + value)
      })
    },
  },
  created () {
    var rating_key = 'story/' + this.story.story_id
    var api_uri = '/get_rating?rating_key=' + rating_key
    this.api.get(api_uri, (data) => {
      var story_rating = JSON.parse(data)[0].rating
      this.set_rating((story_rating) ? parseFloat(story_rating) : 0)
    })
  },
}
</script>

<style scoped>
h1 {
  color: #5e0080;
  font-size: 2.5em;
  font-weight: bold;
}

main {
  margin: auto auto;
  width: 800px;
  padding-bottom: 50px;
}

.story-text {
  color: #e6f1ff;
  font-family: 'Quicksand', sans-serif;
  font-weight: 200;
  font-size: 1.5em;
  letter-spacing: 1.2px;
}

.rating {
  margin-top: 50px;
  margin-bottom: 50px;
  color: #5e0080;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: 1.8em;
}
@media screen and (max-width: 992px) {
  .story-text {
    font-size: 2.5em;
  }
}
</style>
