
module.exports = function($)
{
  var api = {}

  api.baseUrl = process.env.VUE_APP_API_BASE

  api.token = ''
  api.bus = null

  api.setBus = function(bus)
  {
    api.bus = bus
  }

  api.callCallback = function(callback, data, ...args)
  {
    if (api.bus !== null && data.message)
    {
      api.bus.$emit('message', data.message)
    }

    callback(data, ...args)
  }

  api.get = function(path, callback, ...args)
  {
    $.ajax({
      type: 'GET',
      url: api.baseUrl + path,
      headers: {
        'X-AUTH-TOKEN': api.token
      },
      success: (data) => { api.callCallback(callback, data, ...args) }
    })
  }

  api.post = function(path, postData, callback, ...args)
  {
    $.ajax({
      type: 'POST',
      url: api.baseUrl + path,
      data: postData,
      headers: {
        'X-AUTH-TOKEN': api.token
      },
      success: (data) => { api.callCallback(callback, data, ...args) }
    })
  }

  /*
  api.upload = function(postData, callback, progressCallback)
  {
    $.ajax({
      xhr: function()
      {
        var xhr = new window.XMLHttpRequest();
        //Upload progress
        xhr.upload.addEventListener('progress', progressCallback)
        //Download progress
        //xhr.addEventListener('progress', progressCallback)
        return xhr;
      },
      type: 'POST',
      url: api.baseUrl + '/media/upload',
      data: postData,
      processData: false, //tell jquery not to process data
      contentType: false, //tell jquery not to set content-type
      headers: {
        'X-AUTH-TOKEN': api.token
      },
      success: callback
    });
  }
  */

  api.setToken = function(token)
  {
    api.token = token
    //console.log('token: ', token)
  }

  return api
}

/*
        xhr.upload.addEventListener("progress", function(evt){
          if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            //Do something with upload progress
            console.log(percentComplete);
          }
        }, false);
        xhr.addEventListener("progress", function(evt){
          if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            //Do something with download progress
            console.log(percentComplete);
          }
        }, false);
*/
